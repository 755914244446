import { TaskActions } from 'context/types'
import cloneDeep from 'lodash/cloneDeep'

export default (state, action) => {
  switch (action.type) {
    case TaskActions.GET_ALL_ASSIGNMENTS:
      return {
        ...state,
        assignments: (action.payload || []).reduce((acc, assignment) => {
          acc[assignment.groupId] = assignment
          return acc
        }, {})
      }
    case TaskActions.GET_SELECTED_ASSIGNMENT_POLICIES:
      return {
        ...state,
        selectedAssignmentPolicies: cloneDeep(action.payload)
      }
    case TaskActions.CLONE_SELECTED_ASSIGNMENT_POLICIES:
      return {
        ...state,
        clonedAssignmentPolicies: cloneDeep(action.payload)
      }
    case TaskActions.GET_COMMON_POLICIES:
      return {
        ...state,
        commonPolicies: action.payload
      }
    case TaskActions.SHOW_COMMON_POLICIES:
      return {
        ...state,
        showCommonPolicies: cloneDeep(action.payload)
      }
    case TaskActions.CLONE_SAVED_ASSIGNMENT_POLICIES:
      return {
        ...state,
        clonedSavedAssignmentPolicies: cloneDeep(action.payload)
      }

    case TaskActions.GET_ALL_POLICIES:
      return {
        ...state,
        policies: action.payload
      }

    default:
      return state
  }
}
