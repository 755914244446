import React from 'react'
import { ShellProps } from './types/shell'
import projectNames from './configs/projectNames'
import RouteTasksTab from 'components/tasks/routeTasksTab'
import ConfigProvider from 'context/config/ConfigProvider'
import TasksProvider from 'context/tasks/TasksProvider'
import CollectionsProvider from 'context/tasks/CollectionsProvider'
import ModalProvider from 'context/modal/ModalProvider'
import ErrorProvider from 'context/tasks/ErrorProvider'
import { ThemeProvider, ToastProvider, useToast } from '@veneer/core'
import { fixTheme } from 'utils/utilities'

declare global {
  interface Window {
    Shell: ShellProps
  }
}
export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const stack = v1?.app?.getAuthStack()
  const authProvider = v1?.authProvider
  const events = v1?.events
  const localization = {
    language: v1?.localization?.language || 'en',
    country: v1?.localization?.country || 'US',
    themeIdentifier: props.themeIdentifier
  }
  const navigation = v1?.navigation
  const accessControl = v1?.accessControl
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.()
  const mfeProps = {
    events,
    navigation,
    accessControl
  }
  const { demoEnabled, apiPath } = props
  const providerProps = { stack, demoEnabled, apiPath, authProvider }
  return (
    <section id={projectNames.packageJsonName}>
      <ThemeProvider {...fixTheme(themeProviderProps)}>
        <ConfigProvider {...localization}>
          <ErrorProvider>
            <ModalProvider>
              <TasksProvider {...providerProps}>
                <CollectionsProvider {...providerProps}>
                  {props.useToast ? (
                    <RouteTasksTab {...props} {...mfeProps} />
                  ) : (
                    <ToastProvider>
                      <RouteTasksTab
                        {...props}
                        {...mfeProps}
                        useToast={useToast}
                      />
                    </ToastProvider>
                  )}
                </CollectionsProvider>
              </TasksProvider>
            </ModalProvider>
          </ErrorProvider>
        </ConfigProvider>
      </ThemeProvider>
    </section>
  )
}
