import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import ReactDOM from 'react-dom'
import {
  Table,
  ButtonGroup,
  Button,
  Modal,
  IconWarningAlt,
  Select,
  IconTrash,
  Scrollbar
} from '@veneer/core'
import { useHistory } from 'react-router-dom'
import { FlexRow, FlexColumn } from 'styles/styles'
import ConfigContext from 'context/config/ConfigContext'
import ModalContext from 'context/modal/ModalContext'
import TasksContext from 'context/tasks/TasksContext'
import CollectionsContext from 'context/tasks/CollectionsContext'
import GroupsPanel from './groupsPanel'
import './index.scss'
import 'styles/global.scss'
import TableFooter from 'components/tasks/tablefooter'
import AddPolicyModal from 'components/tasks/modal/addPolicy'
import ChangePolicyPriority from 'components/tasks/modal/changePolicyPriority'
import CommonAssignmentPolicies from 'components/tasks/taskstable/commonAssignmentPolicies'
import Preview from 'components/tasks/modal/preview'
import _ from 'lodash'
import { TaskType } from 'utils/taskHelper'
import {
  getCategories,
  getGroupName,
  findPredefinedGroup
} from 'utils/utilities'
import {
  TASK_WRITE_SCOPE,
  TASK_DELETE_SCOPE,
  TASK_CREATE_SCOPE
} from 'components/tasks/constants/constant'
import Retrievei18nItems from 'utils/Retrievei18nItems'
import TreeViewComponent from 'components/tasks/taskstable/treeViewComponent'

const reloadEvent = 'ecp-banner-reload-call'
const selectedGroup = 'policiesTasksMfeSelectedGroupId'

const TasksTable = (props) => {
  const pageSize = 8
  const [currentCollectionId, setCurrentCollectionId] = useState(null)
  const { displayToaster, navigation, accessControl, events } = props
  const [lastCollectionId, setLastCollectionId] = useState(null)
  const [previewPolicy, setPolicyForPreview] = useState(null)
  const [blockedUrl, setBlockedUrl] = useState(null)
  const [showSideBar, setShowSideBar] = useState(false)
  const {
    getAssignmentsPolicies,
    getAllAssignments,
    updateAssignmentPolicies,
    cloneAssignmentPolicies,
    assignments,
    selectedAssignmentPolicies,
    clonedSavedAssignmentPolicies,
    showCommonPolicies,
    createAssignment,
    updateAssignment,
    deleteAssignment
  } = useContext(TasksContext)
  const { getAllCollection, collections } = useContext(CollectionsContext)
  const { t, isWex } = useContext(ConfigContext)
  const {
    showAddPolicies,
    hideAddPolicies,
    showChangePolicies,
    settingAlert,
    hideSettingAlert,
    showSettingAlert
  } = useContext(ModalContext)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = navigation || useHistory()

  const [allowWrite, setAllowWrite] = useState(isWex) // TO DO: isWex -> false
  const [allowDelete, setAllowDelete] = useState(isWex) // TO DO: isWex -> false
  const [allowCreate, setAllowCreate] = useState(isWex) // TO DO: isWex -> false

  const checkScopes = async () => {
    setAllowWrite(
      await accessControl.checkScopes([{ scope: TASK_WRITE_SCOPE }])
    )
    setAllowDelete(
      await accessControl.checkScopes([{ scope: TASK_DELETE_SCOPE }])
    )
    setAllowCreate(
      await accessControl.checkScopes([{ scope: TASK_CREATE_SCOPE }])
    )
  }

  useEffect(() => {
    // TO DO: remove !isWex
    if (accessControl && !isWex) {
      checkScopes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl])

  const handleLinkClick = (event, policyId, policyName) => {
    setPolicyForPreview({
      policyId: policyId,
      policyName: policyName
    })
  }

  const scrollableHeight = useMemo(
    () => ({ height: `calc(100vh - ${isWex ? 212 : 390}px)` }),
    [isWex]
  )

  const collectionNodes = useMemo(() => {
    if (!collections?.contents || !assignments) {
      return null
    }

    const treeNodes = collections.contents.map((collection) => ({
      id: collection.id,
      label: getGroupName(collection, t),
      totalChildren: assignments[collection.id]?.policies.length || null
    }))

    const nodesMap = treeNodes
      ? treeNodes.reduce((acc, node) => {
          acc[node.id] = node
          return acc
        }, {})
      : {}

    const parent = findPredefinedGroup(collections.contents, 'All')
    const ungrouped = findPredefinedGroup(collections?.contents, 'Ungrouped')
    const parentNode = parent ? nodesMap[parent.id] : null

    if (parentNode) {
      parentNode.nodes = []
      let ungroupedNode = null

      const nodes = treeNodes.reduce((acc, node) => {
        if (node.id === ungrouped?.id) {
          ungroupedNode = node
        } else if (node.id !== parent.id) {
          node.totalChildren =
            node.totalChildren + parentNode.totalChildren || null
          acc.push(node)
        }
        return acc
      }, [])

      if (ungroupedNode) {
        ungroupedNode.totalChildren =
          ungroupedNode.totalChildren + parentNode.totalChildren || null
        nodes.unshift(ungroupedNode)
      }

      parentNode.nodes = nodes
      return [parentNode]
    }

    return treeNodes
  }, [collections, assignments, t])

  const parentId = useMemo(() => {
    return collectionNodes?.length ? collectionNodes[0].id : null
  }, [collectionNodes])

  useEffect(() => {
    let storedNode = parentId
    if (parentId) {
      storedNode = sessionStorage.getItem(selectedGroup)
      if (
        !collectionNodes
          .find(({ id }) => id === parentId)
          ?.nodes.find(({ id }) => id === storedNode)
      ) {
        storedNode = parentId
        sessionStorage.setItem(selectedGroup, storedNode)
      }
    }
    setCurrentCollectionId(storedNode)
    setLastCollectionId(storedNode)
    getAssignmentsPolicies(storedNode, parentId)
  }, [parentId, collectionNodes])

  const tableData = useMemo(() => {
    const getTaskType = (taskType) => {
      switch (taskType) {
        case TaskType.AssessRemediate:
          return t('task.assessRemediateOptions.assessRemediate')
        case TaskType.Assess:
          return t('task.assessRemediateOptions.assessOnly')
        case TaskType.Remediate:
          return t('task.assessRemediateOptions.remediateOnly')
        default:
          return ''
      }
    }

    const assessRemediateOptions = [
      {
        value: TaskType.AssessRemediate,
        label: t('task.assessRemediateOptions.assessRemediate')
      },
      {
        value: TaskType.Assess,
        label: t('task.assessRemediateOptions.assessOnly')
      }
    ]

    const item = (policy) => {
      return {
        id: policy.policyId,
        rowConfig: {
          selected: false,
          disabled: false
        },
        policyName: (
          <a
            role={'button'}
            onClick={(e) => {
              e.preventDefault()
              handleLinkClick(e, policy.policyId, policy.policyName)
            }}
            id={policy.policyId}
          >
            {policy.policyName}
          </a>
        ),
        category: getCategories(policy.policyAttributes, t),
        assessRemediate: (
          <FlexRow className={'fullWidth'}>
            {allowWrite ? (
              <Select
                placeholder={t('task.footer.selectAction')}
                options={assessRemediateOptions}
                value={[policy.assignmentType] || [TaskType.AssessRemediate]}
                onChange={({ value: v }) =>
                  handleAssessRemediate(v, policy.policyId)
                }
                placement={'bottom-end'}
                clearIcon={false}
              />
            ) : (
              <p>{getTaskType(policy.assignmentType)}</p>
            )}
            {allowWrite && (
              <div className={'trash-button-margin'}>
                <Button
                  id={'task-trash-button'}
                  appearance={'ghost'}
                  small={true}
                  leadingIcon={<IconTrash />}
                  onClick={() => deleteRow(policy.policyId)}
                />
              </div>
            )}
          </FlexRow>
        )
      }
    }

    return selectedAssignmentPolicies
      ? selectedAssignmentPolicies.map((policy) => item(policy))
      : null
  }, [selectedAssignmentPolicies, allowWrite])

  const reload = useCallback(() => {
    getAllAssignments(displayToaster)
    getAllCollection(displayToaster)
  }, [])

  useEffect(() => reload(), [reload])

  useEffect(() => {
    if (events) {
      events.addEventListener(reloadEvent, reload)
      return () => {
        events.removeEventListener(reloadEvent, reload)
      }
    }
  }, [events, reload])

  const addNoPolicyMessage = (id, msg) => {
    const targetSpan = document
      ?.getElementById(id)
      ?.getElementsByTagName('span')[0]
    const element = (
      <FlexColumn>
        <span data-testid={'no-data-label'}>{t('task.table.noPolicies')}</span>
        <span data-testid={'no-data-label'}>{msg}</span>
      </FlexColumn>
    )
    if (targetSpan) {
      ReactDOM.render(element, targetSpan)
    }
  }

  useEffect(() => {
    if (tableData?.length === 0) {
      addNoPolicyMessage('assignments', t('task.table.noItemsDescription'))
    }
  }, [tableData])

  const anyChanges =
    clonedSavedAssignmentPolicies && selectedAssignmentPolicies
      ? _.isEqual(clonedSavedAssignmentPolicies, selectedAssignmentPolicies)
      : true

  useEffect(() => {
    return history.block((ev) => {
      if (blockedUrl) {
        setBlockedUrl(null)
      } else if (!anyChanges) {
        setBlockedUrl(ev.pathname)
        return false
      }
      return true
    })
  }, [history, blockedUrl, anyChanges])

  const currentCollection = collections?.contents?.find(
    (row) => row.id === currentCollectionId
  )

  const params = {
    numberOfPolicies: tableData?.length || 0,
    group: getGroupName(currentCollection, t) || ''
  }

  const handleAssessRemediate = async (value, policyId) => {
    const updatedAssignmentPolicies = selectedAssignmentPolicies.map(
      (policy) => {
        if (policy.policyId === policyId) {
          policy.assignmentType = value
        }
        return policy
      }
    )
    await updateAssignmentPolicies(updatedAssignmentPolicies)
  }

  const onCancelClick = () =>
    handleSelectedCollectionGroup(currentCollectionId, true)

  const assignmentExist = () => !!assignments?.[currentCollectionId]

  const onAssignClick = async () => {
    const data = {
      policies: selectedAssignmentPolicies.map((policy) => {
        return {
          policyId: policy.policyId,
          assignmentType: policy?.assignmentType
            ? policy?.assignmentType
            : TaskType.AssessRemediate
        }
      }),
      groupId: currentCollectionId
    }
    await createAssignment(data, () =>
      displayToaster('assign-toaster-id', t('task.toaster.assignSuccess'))
    )
    await getAllAssignments(displayToaster)
  }

  const onSaveClick = async () => {
    const data = {
      policies: selectedAssignmentPolicies.map((policy) => {
        return {
          policyId: policy.policyId,
          assignmentType: policy?.assignmentType
            ? policy?.assignmentType
            : TaskType.AssessRemediate
        }
      }),
      groupId: currentCollectionId
    }

    await updateAssignment(data, assignments[currentCollectionId]?.id, () =>
      displayToaster('save-toaster-id', t('task.toaster.saveSuccess'))
    )
    await getAllAssignments(displayToaster)
  }

  const onDeleteClick = async () => {
    await deleteAssignment(assignments[currentCollectionId]?.id, () =>
      displayToaster('delete-toaster-id', t('task.toaster.deleteSuccess'))
    )
    await getAllAssignments(displayToaster)
  }

  const handleShowTaskAddPolicy = () => showAddPolicies()

  const handleShowChangePolicyPriorityModal = () => {
    cloneAssignmentPolicies(selectedAssignmentPolicies)
    showChangePolicies()
  }

  const addPolicyToTable = async (selectedPolicies) => {
    const policiesToAdd = []
    const filteredPolicies = []
    selectedAssignmentPolicies.map((sap) => {
      const isPreviousPresent = selectedPolicies.find(
        (sp) => sp.id === sap.policyId
      )
      if (isPreviousPresent) {
        filteredPolicies.push(sap)
      }
    })
    selectedPolicies.map((sp) => {
      const isPresent = filteredPolicies.find((td) => td.policyId === sp.id)
      if (!isPresent) {
        policiesToAdd.push(sp)
      }
    })
    const updatedData = [...filteredPolicies, ...policiesToAdd]
    const finalData = updatedData.map((ud) => {
      return {
        policyId: ud.id || ud.policyId,
        policyName: ud.policyName,
        policyAttributes: ud.policyAttributes
          ? cleanPolicyAttributes(ud.policyAttributes)
          : [],
        assignmentType: ud.assignmentType
          ? ud.assignmentType
          : TaskType.AssessRemediate
      }
    })
    await updateAssignmentPolicies(finalData)
    hideAddPolicies()
  }

  const cleanPolicyAttributes = (policyAttributes) => {
    return policyAttributes.map((pa) => {
      return {
        name: pa.name
      }
    })
  }

  const deleteRow = async (policyId) => {
    const updatedPolices = selectedAssignmentPolicies?.filter(
      (row) => row.policyId !== policyId
    )
    await updateAssignmentPolicies(updatedPolices)
  }

  const handleSelectedCollectionGroup = useCallback(
    async (collectionId, leaveSetting) => {
      setCurrentCollectionId(collectionId)
      sessionStorage.setItem(selectedGroup, collectionId)
      if (!anyChanges && !leaveSetting) {
        showSettingAlert()
        return
      }
      setLastCollectionId(collectionId)
      await getAssignmentsPolicies(collectionId, parentId)
    },
    [anyChanges, showSettingAlert, getAssignmentsPolicies, parentId]
  )

  const saveChangePolicyPriority = (changePolicyData) => {
    updateAssignmentPolicies(changePolicyData)
  }

  function onLeaveSetting() {
    hideSettingAlert()
    handleSelectedCollectionGroup(currentCollectionId, true)
    setBlockedUrl(null)
    if (blockedUrl) {
      history.push(blockedUrl)
    }
  }

  const onCancelSetting = () => {
    hideSettingAlert()
    setCurrentCollectionId(lastCollectionId)
    setBlockedUrl(null)
  }

  const WarningUnsavedChangesModal = (
    <Modal
      id={'warningUnsavedChanges-modal'}
      onClose={() => onCancelSetting()}
      closeOnBlur={false}
      show={settingAlert || !!blockedUrl}
      className={'task-warning-modal'}
      footer={
        <ButtonGroup>
          <Button
            id={'unsaved-changes-confirm-button'}
            onClick={() => onLeaveSetting()}
          >
            {t('task.settingModal.leave')}
          </Button>
          <Button
            id={'unsaved-changes-cancel-button'}
            appearance={'secondary'}
            onClick={() => onCancelSetting()}
          >
            {t('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <FlexRow className={'modalTitleBox'}>
        <IconWarningAlt size={36} filled color={'darkOrange6'} />
        <h4 className={'marginLeft8'}>{t('task.settingModal.title')}</h4>
      </FlexRow>
      <p className={'paddingBottom16'}>{t('task.settingModal.message1')}</p>
      <b>{t('task.settingModal.message2')}</b>
    </Modal>
  )

  const treeViewComponent = useMemo(() => {
    const treeViewProps = {
      settingAlert,
      lastCollectionId,
      currentCollectionId,
      handleSelectedCollectionGroup,
      collectionNodes,
      parentId
    }

    return parentId ? <TreeViewComponent {...treeViewProps} /> : null
  }, [
    settingAlert,
    lastCollectionId,
    currentCollectionId,
    collectionNodes,
    parentId
  ])

  return (
    <>
      <div className={'tasks-headline'}>{t('task.headline')}</div>
      {showAddPolicies && (
        <AddPolicyModal
          tableList={tableData}
          addPolicyToTable={addPolicyToTable}
          {...props}
        />
      )}
      {showChangePolicies && (
        <ChangePolicyPriority
          tableList={tableData}
          saveChangePolicyPriority={saveChangePolicyPriority}
        />
      )}
      {previewPolicy && (
        <Preview
          previewPolicy={previewPolicy}
          setPolicyForPreview={setPolicyForPreview}
          {...props}
        />
      )}
      {WarningUnsavedChangesModal}
      <FlexRow style={scrollableHeight}>
        <GroupsPanel show={showSideBar} onChange={(x) => setShowSideBar(x)}>
          <div className={'task-group-column'}>
            <div className={'task-group-title'}>{t('task.groups')}</div>
            {treeViewComponent}
          </div>
        </GroupsPanel>
        <div className={showSideBar ? 'main-table-sm' : 'main-table'}>
          <Scrollbar>
            <div className={'justifyContentSpaceBetween tasks-table-title-box'}>
              <div className={'tasks-table-title'}>
                {t('task.policiesAddedMsg', params)}
              </div>
              {(assignmentExist() ? allowWrite : allowCreate) && (
                <ButtonGroup className={'marginLeft12'}>
                  <Button
                    id={'modifyPolicy-btn'}
                    appearance={'secondary'}
                    disabled={
                      !selectedAssignmentPolicies ||
                      selectedAssignmentPolicies.length <= 1
                    }
                    onClick={handleShowChangePolicyPriorityModal}
                  >
                    {t('task.changePolicyPriorityBtn')}
                  </Button>
                  <Button
                    id={'addPolicy-btn'}
                    appearance={'secondary'}
                    disabled={!selectedAssignmentPolicies}
                    onClick={handleShowTaskAddPolicy}
                  >
                    {t('common.add')}
                  </Button>
                </ButtonGroup>
              )}
            </div>
            <div id={'assignments'} className={'widthMinusBorder'}>
              <Table
                columns={[
                  {
                    id: 'id',
                    label: 'id',
                    index: 'hidden'
                  },
                  {
                    id: 'policyName',
                    label: t('task.columnHeader.policyName')
                  },
                  {
                    id: 'category',
                    label: t('task.columnHeader.category')
                  },
                  {
                    id: 'assessRemediate',
                    label: t('task.columnHeader.assessRemediate')
                  }
                ]}
                data={tableData || []}
                loading={!tableData && collectionNodes?.length !== 0}
                loadingDataLength={pageSize}
                className={'assignment-list'}
                preferences={{
                  width: [
                    { columnId: 'policyName', width: 250 },
                    { columnId: 'category', width: 150 },
                    { columnId: 'assessRemediate', width: 350 }
                  ]
                }}
                i18n={Retrievei18nItems()}
              />
            </div>
            {showCommonPolicies && (
              <div className={'widthMinusBorder marginTop24'}>
                <CommonAssignmentPolicies
                  currentCollection={currentCollection}
                  handleLinkClick={handleLinkClick}
                  addNoPolicyMessage={addNoPolicyMessage}
                />
              </div>
            )}
          </Scrollbar>
        </div>
      </FlexRow>
      {!anyChanges && (
        <TableFooter
          action={
            !assignmentExist()
              ? { name: 'assign', onClick: onAssignClick }
              : selectedAssignmentPolicies.length || !allowDelete
              ? { name: 'save', onClick: onSaveClick }
              : { name: 'unassign', onClick: onDeleteClick }
          }
          onCancelClick={onCancelClick}
          disabledAction={
            assignmentExist() &&
            !(selectedAssignmentPolicies.length || allowDelete)
          }
        />
      )}
    </>
  )
}

export default React.memo(TasksTable)
