import React, { useReducer, useContext } from 'react'
import axios from 'axios'
import CollectionsContext from 'context/tasks/CollectionsContext'
import CollectionsReducer from 'context/tasks/CollectionsReducer'
import { CollectionsActions } from 'context/types'
import { CollectionClient } from '@jarvis/web-stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import ConfigContext from 'context/config/ConfigContext'
import { errorReducer } from 'context/ErrorReducer'

const CollectionsProvider = (props) => {
  const { t } = useContext(ConfigContext)
  const initialState = { collections: null }
  const [state, dispatch] = useReducer(CollectionsReducer, initialState)
  const { stack = Stack.dev, demoEnabled, apiPath, authProvider } = props
  const demoUrl = (x) => apiPath('demo', 'generic', 'printMfeCache', x)
  const collectionClient = new CollectionClient(stack, authProvider)

  const displayCollections = (payload) => {
    dispatch({ type: CollectionsActions.GET_ALL_COLLECTIONS, payload })
  }

  const getAllCollection = async (displayToast) => {
    try {
      displayCollections(null)
      const res = demoEnabled
        ? await axios.get(demoUrl('collections'))
        : await collectionClient.getCollections(0, -1)
      displayCollections(res.data)
    } catch (error) {
      const newError = errorReducer(error, t).error
      displayCollections({ contents: [] })
      displayToast(
        'getAllCollection-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  return (
    <CollectionsContext.Provider
      value={{
        collections: state.collections,
        getAllCollection
      }}
    >
      {props.children}
    </CollectionsContext.Provider>
  )
}

export default CollectionsProvider
