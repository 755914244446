import React, { useReducer, useContext } from 'react'
import TasksContext from 'context/tasks/TasksContext'
import TasksReducer from 'context/tasks/TasksReducer'
import { TaskActions, TaskType } from 'context/types'
import { FleetMgtSvcClient } from '@jarvis/web-stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import ErrorContext from 'context/tasks/ErrorContext'
import ConfigContext from 'context/config/ConfigContext'
import { errorReducer } from 'context/ErrorReducer'
import axios from 'axios'

const limit = 500

const TasksProvider = (props) => {
  const { showError } = useContext(ErrorContext)
  const { t } = useContext(ConfigContext)
  const initialState = {
    policies: null,
    assignments: null,
    selectedAssignmentPolicies: null,
    clonedAssignmentPolicies: [],
    clonedSavedAssignmentPolicies: [],
    showCommonPolicies: false,
    commonPolicies: []
  }
  const [state, dispatch] = useReducer(TasksReducer, initialState)
  const { stack = Stack.dev, demoEnabled, apiPath, authProvider } = props
  const demoUrl = (x) => apiPath('demo', 'generic', 'printMfeCache', x)
  const fleetMgtSvcClient = new FleetMgtSvcClient(stack, authProvider)

  async function getPoliciesAssignments(type) {
    let offset = 0

    // To get the total number of policies or assignments
    const firstPage =
      type === TaskType.POLICIES
        ? await fleetMgtSvcClient.getAllPolicies(offset, limit)
        : await fleetMgtSvcClient.getAllAssignments(offset, limit)

    const totalRecords = firstPage.data.size
    offset += limit

    if (totalRecords <= limit) {
      return firstPage
    }

    const promises = []
    while (offset < totalRecords) {
      type === TaskType.POLICIES
        ? promises.push(fleetMgtSvcClient.getAllPolicies(offset, limit))
        : promises.push(fleetMgtSvcClient.getAllAssignments(offset, limit))
      offset += limit
    }
    const results = await Promise.all(promises)
    const resultsData = {
      data: {
        items: [
          ...firstPage.data.items,
          ...results.flatMap((res) => res.data.items)
        ]
      }
    }

    return resultsData
  }

  const getAllPolicies = async (displayToast) => {
    try {
      displayPolicies(null)
      const res = demoEnabled
        ? await axios.get(demoUrl('policies'))
        : await getPoliciesAssignments(TaskType.POLICIES)
      displayPolicies(res.data)
    } catch (error) {
      displayPolicies({ items: [] })
      const newError = errorReducer(error, t).error
      displayToast(
        'getAllPolicies-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  const getAllAssignments = async (displayToast) => {
    try {
      displayAssignments(null)
      const res = demoEnabled
        ? await axios.get(demoUrl('assignments'))
        : await getPoliciesAssignments(TaskType.ASSIGNMENTS)
      displayAssignments(res.data.items)
    } catch (error) {
      displayAssignments([])
      const newError = errorReducer(error, t).error
      displayToast(
        'getAllAssignments-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  const getAssignmentsPolicies = (collectionId, parentId) => {
    if (!state.assignments || !collectionId) {
      displayAssignmentPolicies(null)
      cloneSavedPolicies(null)
      displayCommonPolicies(null)
      showCommonPolicies(false)
      return
    }

    const assignment = state.assignments[collectionId]
    displayAssignmentPolicies(assignment?.policies || [])
    cloneSavedPolicies(assignment?.policies || [])

    if (collectionId !== parentId) {
      const parentAssignment = state.assignments[parentId]
      displayCommonPolicies(parentAssignment?.policies || [])
      showCommonPolicies(true)
    } else {
      displayCommonPolicies([])
      showCommonPolicies(false)
    }
  }

  const displayCommonPolicies = (payload) => {
    dispatch({ type: TaskActions.GET_COMMON_POLICIES, payload })
  }

  const showCommonPolicies = (payload) => {
    dispatch({ type: TaskActions.SHOW_COMMON_POLICIES, payload })
  }

  const cloneSavedPolicies = (payload) => {
    dispatch({ type: TaskActions.CLONE_SAVED_ASSIGNMENT_POLICIES, payload })
  }

  const updateAssignmentPolicies = (updatedPolicies) => {
    try {
      displayAssignmentPolicies(updatedPolicies || [])
    } catch (error) {
      showError(error)
    }
  }

  const cloneAssignmentPolicies = (policies) => {
    try {
      clonePolicies(policies || [])
    } catch (error) {
      showError(error)
    }
  }

  const clonePolicies = (payload) => {
    dispatch({ type: TaskActions.CLONE_SELECTED_ASSIGNMENT_POLICIES, payload })
  }

  const displayAssignments = (payload) => {
    dispatch({ type: TaskActions.GET_ALL_ASSIGNMENTS, payload })
  }

  const displayAssignmentPolicies = (payload) => {
    dispatch({ type: TaskActions.GET_SELECTED_ASSIGNMENT_POLICIES, payload })
  }

  const createAssignment = async (assignment, displayToast) => {
    try {
      if (!demoEnabled) {
        await fleetMgtSvcClient.createAssignment(assignment)
      }
      displayToast()
    } catch (error) {
      showError(error)
    }
  }

  const updateAssignment = async (assignment, id, displayToast) => {
    try {
      if (!demoEnabled) {
        await fleetMgtSvcClient.updateAssignment({ ...assignment, id })
      }
      displayToast()
    } catch (error) {
      showError(error)
    }
  }

  const deleteAssignment = async (id, displayToast) => {
    try {
      if (!demoEnabled) {
        await fleetMgtSvcClient.deleteAssignment(id)
      }
      displayToast()
    } catch (error) {
      showError(error)
    }
  }

  const displayPolicies = (payload) => {
    dispatch({ type: TaskActions.GET_ALL_POLICIES, payload })
  }

  return (
    <TasksContext.Provider
      value={{
        policies: state.policies,
        assignments: state.assignments,
        selectedAssignmentPolicies: state.selectedAssignmentPolicies,
        clonedAssignmentPolicies: state.clonedAssignmentPolicies,
        clonedSavedAssignmentPolicies: state.clonedSavedAssignmentPolicies,
        showCommonPolicies: state.showCommonPolicies,
        commonPolicies: state.commonPolicies,
        getAllPolicies,
        getAllAssignments,
        getAssignmentsPolicies,
        updateAssignmentPolicies,
        cloneAssignmentPolicies,
        createAssignment,
        updateAssignment,
        deleteAssignment
      }}
    >
      {props.children}
    </TasksContext.Provider>
  )
}

export default TasksProvider
